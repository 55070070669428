<template>
    <div>
        <div class="p-4">
            <h2 class="mb-4">Welcome to Access Right To Work</h2>
            <div class="row">
                <div class="col-9">
                    <p>Access Right to Work is an easy to use mobile app and cloud-based management portal.</p>
                    <p>In less than six steps it creates and records the statutory excuse of an applicant's eligibility
                        to
                        work in the UK, as well as storing proof of compliance and keeping track of people who have time
                        limited permissions to work in the UK.</p>
                    <p>Access Right to Work is designed for you to use with a Pay As You Go model, allowing you to pay
                        for
                        checks as and when you need them.</p>
                    <p>By clicking "Click here to proceed", you will be guided through the configuration wizard, which
                        will allow you to customise the product and assign access rights and permissions. Access Right
                        to Work will then be available for you to carry out Right to Work checks with applicants.</p>
                </div>
            </div>
        </div>
        <div class="p-4  d-flex justify-content-end">

            <b-button :to="{name:'setup_company_details'}" variant="success">
                Click here to proceed
            </b-button>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";

    export default {
        name: 'setup_welcome',

        methods: {},
        components: {},
    }
</script>

